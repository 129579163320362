<template>
  <div class="list">
    <h3 class="step-title">
      <span>
        <span>
          <span>
            <a>{{ $t("editTrip.effortStep.title") }} </a>
          </span>
        </span>
      </span>
    </h3>
    <fieldset>
      <div v-if="!viewHandler.effortFormOpen" class="effortPreviewContainer">
        <div class="effortSummary">
          <!-- repeter btn-edit-sum pour chaque effort, {0} est l'index base 1 -->
          <div
            v-for="(effort, index) in form.efforts"
            :key="index"
            class="btn-edit-sum"
          >
            <b-overlay
              :show="effort['closeDatetime'] !== undefined"
              rounded="sm"
              blur="none"
            >
              <template #overlay>
                <b-row>
                  <b-col>
                    <p class="redMessage">
                      {{ $t("editTrip.effortStep.effortClose") }}
                    </p>
                    <br />
                    <p class="redMessage">
                      {{ $t("date") }}:{{
                        showDate(effort["closeDatetime"]).split(",")[0]
                      }}
                    </p>
                  </b-col>
                </b-row>
              </template>
              <a @click="editNewEffort(index)">
                <div class="title">
                  <a> </a>
                </div>
                <div class="container">
                  <div class="first">{{ effort.start }}</div>
                  <div class="second">
                    | <span v-if="effort.tows">{{ effort.tows.length }}</span>
                    {{ $t("editTrip.effortStep.tows") }} |
                    <span
                      v-if="
                        effort.tows &&
                          effort.tows[0] &&
                          effort.tows[0].catches &&
                          effort.tows[0].catches[0]
                      "
                      class="weight"
                      >{{
                        Math.round(effort.tows[0].catches[0]?.keptWeight)
                      }}
                      kg |</span
                    >
                  </div>
                  <div class="action">
                    {{ $t("editTrip.effortStep.edit") }} (+)
                  </div>
                </div>
              </a>
            </b-overlay>
          </div>
        </div>
        <div class="effortButton">
          <div @click="addNewEffort()" class="btn-edit-add">
            <a
              class="btn-effort"
              :class="form.efforts.length === 0 ? 'btn-error' : 'btn-tow'"
            >
              <div class="text">
                <a>
                  {{ $t("editTrip.effortStep.addEffort") }}
                </a>
              </div>
              <div class="plus-icon">+</div>
            </a>
          </div>
        </div>
      </div>
      <div v-if="viewHandler.effortFormOpen && form.efforts.length > 0">
        <h4 class="sectionTitle">
          <span>{{ $t("editTrip.effortStep.effort") }}</span>
          {{ viewHandler.effortIndex + 1 }}
        </h4>

        <fieldset v-if="selectedLicence">
          <h4>{{ $t("editTrip.effortStep.fishingInfos") }}</h4>
          <Select
            v-if="licencesOptions.length > 2"
            :label="$t('editTrip.effortStep.licenceNumber')"
            v-model="form.efforts[viewHandler.effortIndex].licenceNumber"
            :options="licencesOptions"
            :required="true"
          />

          <FishingInfoDisplay
            :licence="selectedLicence"
            :effort="form.efforts[viewHandler.effortIndex]"
            :displayLicenceNumber="licencesOptions.length === 2"
            :index="viewHandler.effortIndex"
            :addErrorToParent="addErrorEffort"
          />
          <!-- TODO: if the type of fishing can change, then move into FishingInfoDisplay. Otherwise, delete !!
          <Select
            v-if="hasEffortFishingType"
            :label="$t('editTrip.effortStep.fishingType')"
            :options="systemsLists.fishingType"
            v-model="form.efforts[viewHandler.effortIndex]['fishingType']"
            :required="hasEffortFishingType === $const.MANDATORY"
            @error="addErrorTow"
            :refeshError="refreshTowError + refreshEffortError"
          />
          -->
        </fieldset>

        <BeginEffort
          :show-error-data="refreshEffortError"
          :effort="form.efforts[viewHandler.effortIndex]"
          :addErrorToParent="addErrorEffort"
          :form="form"
          :licence="selectedLicence"
          :index="viewHandler.effortIndex"
        />
        <FishingGears
          :effort="form.efforts[viewHandler.effortIndex]"
          :addErrorToParent="addErrorEffort"
          :show-error-data="refreshEffortError"
        />
        <Methods
          :gear="form.efforts[viewHandler.effortIndex].gears"
          :subform="subform"
          :initialMethods="form.efforts[viewHandler.effortIndex].fishingMethods"
          :addErrorToParent="addErrorEffort"
          :show-error-data="refreshEffortError"
          @update-fishingMethods="
            methods => updateFishingMethods(viewHandler.effortIndex, methods)
          "
        />
        <BaitFasteners
          :gear="form.efforts[viewHandler.effortIndex].gears"
          :subform="openTrip.subform"
          :initial-gear-bait-fastner="
            form.efforts[viewHandler.effortIndex].baitFasteners
          "
          :addErrorToParent="addErrorEffort"
          :show-error-data="refreshEffortError"
          @update-baitFasteners="
            baitFasteners =>
              updateBaitFasteners(viewHandler.effortIndex, baitFasteners)
          "
        />

        <fieldset v-if="!viewHandler.towFormOpen">
          <div v-if="!viewHandler.towFormOpen">
            <div>
              <div
                v-for="(tow, index) in form.efforts[viewHandler.effortIndex]
                  .tows"
                :key="index"
                class="btn-edit-sum"
              >
                <a
                  id="btn-tow-edit"
                  class="btn-tow-sum"
                  @click="editNewTow(index)"
                  href="#tow"
                >
                  <div class="title">
                    {{ $t("editTrip.effortStep.tow") }}&nbsp;{{ index + 1 }}
                  </div>
                  <div class="container">
                    <div>{{ target }}</div>
                    <div>{{ Math.round(tow?.catches[0]?.keptWeight) }} kg</div>
                    <div class="action">
                      {{ $t("editTrip.effortStep.edit") }} (+)
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div @click="addNewTow" name="idTow">
              <div class="btn-edit-add">
                <a
                  class="btn-tow"
                  :class="
                    form.efforts[viewHandler.effortIndex].tows.length === 0
                      ? 'btn-error'
                      : 'btn-tow'
                  "
                >
                  <div class="text">
                    {{ $t("editTrip.effortStep.addTows") }}
                  </div>
                  <div class="plus-icon">+</div>
                </a>
              </div>
              <label
                v-if="form.efforts[viewHandler.effortIndex].tows.length === 0"
                class="spacing error"
              >
                {{ $t("addTowError") }}
              </label>
            </div>
          </div>
        </fieldset>
        <div
          v-if="
            viewHandler.towFormOpen &&
              form.efforts[viewHandler.effortIndex].tows.length > 0
          "
        >
          <fieldset class="tow removeBottomPadding">
            <h4
              class="sectionTitle"
              :style="{ top: form.efforts.length > 1 ? '55px' : '0px' }"
            >
              {{ $t("editTrip.effortStep.tow") }}&nbsp;{{
                viewHandler.towIndex + 1
              }}
              <span
                v-b-modal="
                  'modal-Trip-' +
                    viewHandler.effortIndex +
                    '-' +
                    viewHandler.towIndex +
                    '-tow'
                "
                v-show="
                  form.efforts[viewHandler.effortIndex]['closeDatetime'] ===
                    undefined
                "
                class="add-remark-btn-title"
              ></span>
            </h4>
            <Popup
              v-model="
                form.efforts[viewHandler.effortIndex].tows[
                  viewHandler.towIndex
                ]['remark']
              "
              :title-label="$t('editTrip.remarkTitle')"
              :maxlength="2000"
              :modalName="
                'modal-Trip-' +
                  viewHandler.effortIndex +
                  '-' +
                  viewHandler.towIndex +
                  '-tow'
              "
            />

            <Tow
              :refreshError="refreshTowError + refreshEffortError"
              :addError="
                error => {
                  addErrorTow(error);
                }
              "
              :tow="
                form.efforts[viewHandler.effortIndex].tows[viewHandler.towIndex]
              "
              :target="form.efforts[viewHandler.effortIndex].target"
              :area="form.efforts[viewHandler.effortIndex].area"
              :effort="form.efforts[viewHandler.effortIndex]"
              @update="update"
            />

            <Info
              :addError="
                error => {
                  addErrorTow(error);
                }
              "
              :effortIndex="viewHandler.effortIndex"
              :effort="form.efforts[viewHandler.effortIndex]"
              :towRefreshError="refreshEffortError + refreshTowError"
              :tow="
                form.efforts[viewHandler.effortIndex].tows[viewHandler.towIndex]
              "
              @update="update"
            />
            <CatchManager
              :effortIndex="viewHandler.effortIndex"
              :towIndex="viewHandler.towIndex"
              :showModal="
                form.efforts[viewHandler.effortIndex]['closeDatetime'] ===
                  undefined
              "
              :effort="this.form.efforts[viewHandler.effortIndex]"
              :tow="
                this.form.efforts[viewHandler.effortIndex].tows[
                  viewHandler.towIndex
                ]
              "
              :showErrorData="refreshEffortError + refreshTowError"
              :addError="
                error => {
                  this.addErrorTow(error);
                }
              "
              @update-catches="
                data => {
                  updateCatches(viewHandler, data);
                }
              "
              @update-bycatches="
                bycatches => {
                  form.efforts[viewHandler.effortIndex].tows[
                    viewHandler.towIndex
                  ].bycatches = bycatches;
                }
              "
            />
            <BaitUsed
              v-if="hasBaitTowNum"
              :baitUsages="
                form.efforts[viewHandler.effortIndex].tows[viewHandler.towIndex]
                  .baitUsages
              "
              :addError="
                error => {
                  this.addErrorTow(error);
                }
              "
              :showErrorData="refreshEffortError"
            />
            <Tags
              v-if="hasTagNum1 && hasTagTowNum"
              :tags="
                form.efforts[viewHandler.effortIndex].tows[viewHandler.towIndex]
                  .tags
              "
              :checkError="
                error => {
                  this.addErrorTow(error);
                }
              "
              :showErrorData="refreshEffortError"
            />
          </fieldset>
          <p>
            <a
              @click="
                removeNewTow(viewHandler.effortIndex, viewHandler.towIndex)
              "
              class="removeSuppEntryButton"
              type="button"
              href="#tow"
            >
              {{ $t("editTrip.effortStep.removeThisTow") }}
              {{ viewHandler.towIndex + 1 }}
            </a>
            <a
              @click="saveNewTow()"
              :id="'btn-tow-save' + viewHandler.towIndex"
              class="saveSuppEntryButton"
              type="button"
              href="#tow"
            >
              {{ $t("editTrip.effortStep.saveTow")
              }}{{ viewHandler.towIndex + 1 }}
            </a>
          </p>
        </div>
        <SampleEffort
          v-if="hasSpecimenSample"
          :effort="form.efforts[viewHandler.effortIndex]"
          :showErrorData="refreshEffortError"
          :addError="
            error => {
              this.addErrorBySample(error);
            }
          "
        />
        <EndEffort
          :effort="form.efforts[viewHandler.effortIndex]"
          :addErrorToParent="addErrorEffort"
          :showErrorData="refreshEffortError"
          :form="form"
          :index="viewHandler.effortIndex"
        />
        <p>
          <input
            @click="closeEffort"
            class="closeSuppEntryButton"
            type="button"
            :value="
              $t('editTrip.effortStep.closeEffort') +
                String(viewHandler.effortIndex + 1)
            "
          />
          <!-- TODO: Should use editTrip.effortStep.removeEffort for symmetry with closeEffort and saveEffort. -->
          <input
            @click="removeNewEffort(viewHandler.effortIndex)"
            class="removeSuppEntryButton"
            type="button"
            :value="
              $t('editTrip.effortStep.removeThisEffort') +
                String(viewHandler.effortIndex + 1)
            "
          />
          <input
            @click="saveNewEffort"
            class="saveSuppEntryButton"
            type="button"
            :value="
              $t('editTrip.effortStep.saveEffort') +
                String(viewHandler.effortIndex + 1)
            "
          />
        </p>
      </div>
    </fieldset>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TowModel from "@/models/Tow.js";
import DateFormat from "@/utils/dateFormat.js";
import BeginEffort from "@/components/subformEditTrip/EffortStepComponents/BeginEffort.vue";
import Tow from "@/components/subformEditTrip/EffortStepComponents/Tow/NewTow.vue";
import EndEffort from "@/components/subformEditTrip/EffortStepComponents/EndEffort.vue";
import FishingGears from "@/components/subformEditTrip/EffortStepComponents/FishingGears.vue";
import CatchManager from "@/components/subformEditTrip/EffortStepComponents/Tow/CatchManager.vue";
import Info from "@/components/subformEditTrip/EffortStepComponents/Tow/Info.vue";
import Methods from "@/components/subformEditTrip/EffortStepComponents/Methods.vue";
import BaitFasteners from "@/components/subformEditTrip/EffortStepComponents/BaitFasteners.vue";
import FishingInfoDisplay from "@/components/subformEditTrip/EffortStepComponents/FishingInfoDisplay.vue";
import Select from "@/components/Select.vue";
import SampleEffort from "@/components/subformEditTrip/EffortStepComponents/SampleEffort.vue";
import Tags from "./EffortStepComponents/Tags.vue";
import BaitUsed from "./EffortStepComponents/BaitUsed.vue";
import Popup from "@/components/widgets/Popup.vue";

import {
  keysMatchingValue,
  scrollToTopField,
  createEffort
} from "@/utils/utils";

export default {
  name: "NewEffortsStep",
  components: {
    BeginEffort,
    EndEffort,
    FishingGears,
    CatchManager,
    Info,
    Tow,
    Methods,
    BaitFasteners,
    FishingInfoDisplay,
    Select,
    Tags,
    BaitUsed,
    Popup,
    SampleEffort
  },
  data: () => ({
    viewHandler: {
      effortIndex: 0,
      towIndex: 0,
      effortFormOpen: false,
      towFormOpen: false
    },
    form: {
      efforts: [{}]
    },
    towHasError: false,
    licenceMaxLength: 7,
    firstTime: true,
    gearType: "",
    target: "",
    name: "effortsStep",
    SPECIMEN_MAX: 9999,
    refreshTowError: 0,
    refreshByCatchError: 0,
    refreshEffortError: 0,
    refreshIndex: []
  }),
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      openTrip: state => state.currentOpenTrip,
      userProfile: state => state.userProfile,
      systemsLists: state => state.systemsLists,
      subscription: state => state.currentSubscription,
      trapSizeOptions: state => state.editTrip.trapSizeOptions,
      showError: state => state.editTripSubform.showError,
      isFinalReview: state => state.editTripSubform.isFinalReview,
      currentPage: state => state.editTripSubform.currentPage,
      subform: state => state.editTripSubform.subform,
      stateErrors: state => state.editTripSubform.errors,
      licences: state =>
        Object.values(state.currentSubscription.preferences.licences)
    }),
    // TODO: Check above for the reason the code below is commented out.
    // hasEffortFishingType() {
    //   return this.getPropertyValue("hasEffortFishingType", {
    //     subforms: this.subform
    //   });
    // },
    hasTagNum1() {
      return this.getPropertyValue("hasTagNum1", {
        subforms: this.subform
      });
    },
    hasTagTowNum() {
      return this.getPropertyValue("hasTagTowNum", {
        subforms: this.subform
      });
    },
    hasBaitTowNum() {
      return this.getPropertyValue("hasBaitTowNum", {
        subforms: this.subform
      });
    },
    hasSpecimenSample() {
      return this.getPropertyValue("hasSpecimenSample", {
        subforms: this.subform
      });
    },
    selectedLicence() {
      // find licence from licences with selectedLicenceNumber
      const selectedLicenceNumber = this.form.efforts[
        this.viewHandler.effortIndex
      ].licenceNumber;
      const licence = this.licences.find(
        x => x.fishingLicenceNumber === selectedLicenceNumber
      );
      return licence;
    },
    licencesOptions() {
      const options = this.licences.map(licence => ({
        value: licence.fishingLicenceNumber,
        text: licence.fishingLicenceNumber
      }));
      return [{ value: null, text: "---------", disabled: true }, ...options];
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function() {
        this.$emit("update-form", this.form);
      }
    },
    showError() {
      if (this.isFinalReview || this.currentPage === this.name) {
        ++this.refreshEffortError;
        if (
          this.form.efforts.length === 0 ||
          this.form.efforts[this.viewHandler.effortIndex].tows.length === 0
        ) {
          this.addErrorsToState({
            page: `${this.name}`,
            errors: { idTow: true }
          });
        }
      }
    }
  },
  methods: {
    ...mapActions(["addErrorsToState", "closeObject"]),
    update() {
      this.$emit("update-form", this.form);
    },
    updateFishingMethods(effortIndex, methods) {
      this.form.efforts[effortIndex].fishingMethods = methods;
    },
    updateBaitFasteners(effortIndex, methods) {
      this.form.efforts[effortIndex].baitFasteners = methods;
    },
    async closeEffort() {
      this.refreshEffortError++;
      if (this.form.efforts[this.viewHandler.effortIndex].tows.length === 0) {
        return;
      }
      const wasClosed = await this.closeObject({
        value: this.form.efforts,
        type: this.name,
        index: this.viewHandler.effortIndex,
        vm: this
      });
      if (wasClosed) {
        this.viewHandler.effortFormOpen = false;
        this.viewHandler.towFormOpen = false;
      }
    },
    addErrorEffort(error) {
      this.addErrorsToState({ page: `${this.name}`, errors: error });
    },
    addErrorBySample(error) {
      this.addErrorsToState({
        page: `samples-${this.viewHandler.effortIndex}`,
        errors: error
      });
      this.addErrorEffort(error);
    },
    addErrorByCatch(error) {
      this.addErrorsToState({
        page: `byCatch-${this.viewHandler.effortIndex}-${this.viewHandler.towIndex}`,
        errors: error
      });
      this.addErrorTow(error);
    },
    addErrorTow(error) {
      this.addErrorsToState({
        page: `tow-${this.viewHandler.effortIndex}`,
        errors: error
      });
      this.addErrorEffort(error);
    },
    async addNewTow() {
      const previousTowIsOpen = this.viewHandler.towFormOpen;
      const vm = this;

      if (this.form.efforts[this.viewHandler.effortIndex].tows.length > 0) {
        this.viewHandler.towIndex =
          this.form.efforts[this.viewHandler.effortIndex].tows.length - 1;
        this.viewHandler.towFormOpen = true;

        const vm = this;
        await vm.$nextTick();
      }

      this.refreshTowError++;
      this.refreshByCatchError++;
      await vm.$nextTick();

      const towErrors = keysMatchingValue(
        this.stateErrors,
        true,
        `tow-${this.viewHandler.effortIndex}`
      );

      this.viewHandler.towFormOpen = previousTowIsOpen;
      if (towErrors.length > 0) {
        return;
      }

      this.towFormOpen = false;
      await vm.$nextTick();

      let effort = this.form.efforts[this.viewHandler.effortIndex];
      effort.tows.push(this.defaultTow(this.subscription.preferences));

      this.viewHandler.towIndex = effort.tows.length - 1;
      this.viewHandler.towFormOpen = true;

      this.form.efforts = [...this.form.efforts];

      await vm.$nextTick();
      await vm.$nextTick();
      document.querySelector(".tow").scrollIntoView();
    },
    removeNewTow(index1, index2) {
      this.form.efforts[index1].tows.splice(index2, 1);
      this.viewHandler.towFormOpen = false;
    },
    editNewTow(index1) {
      this.viewHandler.towIndex = index1;
      this.viewHandler.towFormOpen = true;
      setTimeout(this.focusTowSave, 0, index1);
    },
    focusTowSave(towIndex) {
      document.getElementById("btn-tow-save" + towIndex).focus();
    },

    focusTowEdit() {
      document.getElementById("btn-tow-edit").focus();
    },
    async saveNewTow() {
      this.refreshTowError++;
      this.refreshByCatchError++;

      const vm = this;
      vm.$nextTick(() => {
        const fieldsWithErrors = keysMatchingValue(
          this.stateErrors,
          true,
          `tow-${this.viewHandler.effortIndex}`
        );
        if (fieldsWithErrors.length > 0) {
          // nextTick is executed AFTER DOM manipulations (element exists)
          // but BEFORE render, meaning elements may not have bounds and such
          // a second nextTick does not appear to garanty one render (by experimentation)
          // setTimeout puts code in a stack that has a lower priority than rendering.. usually :D
          setTimeout(scrollToTopField, 0, fieldsWithErrors);
          return;
        }

        this.viewHandler.towFormOpen = false;
        setTimeout(this.focusTowEdit, 0);
      });
    },
    addNewEffort() {
      let preferences = this.subscription.preferences;

      this.form.efforts.push(this.defaultEffort(preferences));

      this.viewHandler.effortIndex = this.form.efforts.length - 1;
      this.viewHandler.effortFormOpen = true;
      this.viewHandler.towIndex = 0;
      this.viewHandler.towFormOpen = true;

      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    },
    removeNewEffort(index1) {
      this.form.efforts.splice(index1, 1);
      this.viewHandler.effortFormOpen = false;
      this.viewHandler.effortIndex = this.form.efforts.length - 1;
      this.viewHandler.towIndex = 0;
      this.viewHandler.towFormOpen = false;
    },
    editNewEffort(index) {
      this.viewHandler.effortIndex = index;
      this.viewHandler.effortFormOpen = true;
    },
    async saveNewEffort() {
      const vm = this;
      this.refreshEffortError++;
      await vm.$nextTick();

      const effortErrors = keysMatchingValue(
        this.stateErrors,
        true,
        `${this.name}`
      );
      if (effortErrors.length > 0) {
        scrollToTopField(effortErrors);
        return;
      }

      if (this.form.efforts[this.viewHandler.effortIndex].tows.length === 0) {
        this.addNewTow();
        this.$swal({
          title: this.$t("error"),
          text: this.$t("insertEntries"),
          icon: "error",
          button: "OK"
        });
        return;
      }
      this.viewHandler.effortFormOpen = false;
      this.viewHandler.towFormOpen = false;
    },
    updateForm() {
      if (this.openTrip["creation"] && this.openTrip !== null) {
        this.form.efforts = this.openTrip.efforts;
      }
    },
    updateCatches(handler, data) {
      this.form.efforts[handler.effortIndex].tows[
        handler.towIndex
      ].catches = data;
      this.update();
    },
    initViewHandler() {
      // open last tow of last effort, being the only one not garantied validation
      if (this.openTrip.efforts.length > 0) {
        const effortIndex = this.openTrip.efforts.length - 1;
        const towIndex = this.openTrip.efforts[effortIndex].tows.length - 1;
        this.viewHandler = {
          effortIndex: effortIndex,
          towIndex: towIndex,
          effortFormOpen: true,
          towFormOpen: true
        };
      }
    },
    targetSpecies() {
      for (let i in this.systemsLists.species) {
        if (
          this.systemsLists.species[i].value ===
          this.form.efforts[this.viewHandler.effortIndex]["target"]
        ) {
          this.target = this.systemsLists.species[i].text;
        }
      }
    },
    durationSoak() {
      if (
        this.form.efforts[this.viewHandler.effortIndex].tows[
          this.viewHandler.towIndex
        ] &&
        this.form.efforts[this.viewHandler.effortIndex].tows[
          this.viewHandler.towIndex
        ]["endDate"] &&
        this.form.efforts[this.viewHandler.effortIndex].tows[
          this.viewHandler.towIndex
        ]["startDate"] &&
        this.form.efforts[this.viewHandler.effortIndex].tows[
          this.viewHandler.towIndex
        ]["soakDurationInMinutes"] === null
      ) {
        let duration =
          new Date(
            this.form.efforts[this.viewHandler.effortIndex].tows[
              this.viewHandler.towIndex
            ]["endDate"]
          ).getTime() -
          new Date(
            this.form.efforts[this.viewHandler.effortIndex].tows[
              this.viewHandler.towIndex
            ]["startDate"]
          ).getTime();
        duration = duration / 60000;

        this.form.efforts[this.viewHandler.effortIndex].tows[
          this.viewHandler.towIndex
        ]["soakDurationInMinutes"] = duration;
      }
    },
    defaultTow(preferences) {
      let licence = Object.values(preferences.licences)[0];
      let newTow = new TowModel();
      newTow.init({ secteur: licence.secteur, section: licence.section });
      return newTow;
    },
    defaultEffort(preferences) {
      let licence = Object.values(preferences.licences)[0];
      // Is endGrid missing from effort?
      let effort = createEffort(preferences, licence);

      // no need for acrobatics, just add a default tow since no effort can be towless :)
      effort.tows.push(this.defaultTow(preferences));
      return effort;
    },
    showDate(d) {
      const date = new Date(d);
      return date.toLocaleString(this.$i18n.locale, {
        timeZone: DateFormat.getZone(this.userProfile)
      });
    },
    checkLastTowEndDate(index) {
      if (
        this.form.efforts &&
        this.form.efforts[index] &&
        this.form.efforts[index].tows &&
        this.form.efforts[index].tows.length > 0
      ) {
        const length = this.form.efforts[index].tows.length;
        if (this.form.efforts[index].tows[length - 1].endDate) {
          return this.form.efforts[index].tows[length - 1].endDate;
        }
        if (this.form.efforts[index].tows[length - 1].startDate) {
          return this.form.efforts[index].tows[length - 1].startDate;
        }
      }
      return undefined;
    }
  },
  beforeMount() {
    this.initViewHandler();
    this.updateForm();
    if (this.form.efforts.length === 0) {
      this.addNewEffort();
    }

    if (this.form.efforts[0].tows.length === 0) {
      this.addNewTow();
    }
  }
};
</script>
<style lang="scss" scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.spacing {
  margin-left: 10px;
  padding: 0px;
}

.editTripForm .btn-edit-sum {
  margin: 12px;
  width: 22%;
  display: inline-block;
  vertical-align: top;

  @media screen and (max-width: 450px) {
    width: 90%;
  }
  @media screen and (min-width: 450px) and (max-width: 935px) {
    width: 44%;
  }
  @media screen and (min-width: 935.1px) and (max-width: 880px) {
    width: 21%;
  }

  a {
    width: 75%;
    display: block;
    text-align: center;
    height: auto;
    background: none;
    margin: auto;

    &:hover {
      text-decoration: none;
      .container {
        opacity: 0.7;
      }
    }

    &.btn-tow-sum {
      .container {
        background: $lightColor;
        border-color: $lightColor;
      }
      .container:before {
        border-left: 20px solid $lightColor;
      }
      .title {
        background: $lightColor;
      }
    }

    &.btn-gearloss-sum,
    &.btn-sar-sum {
      .container {
        background: $bleu-trespale;
        border-color: $bleu-trespale;
      }
      .container:before {
        border-left: 20px solid $bleu-trespale;
      }
      .title {
        background: $bleu-trespale;
      }
    }
    .containerClose {
      position: relative;
      background: rgb(65, 65, 65);
      color: rgba($color: #ffffff, $alpha: 0.5);
      display: block;
      text-align: center;
      border-radius: 0 0 0 10px;
      border: 3px solid $darkColor;
      max-height: 110px;
      height: 110px;
      font-size: inherit;
      line-height: 1em;
      // position: relative;
      opacity: 0.4;
      div {
        line-height: 1.5em;
      }

      .action {
        position: absolute;
        bottom: 0px;
        width: 100%;
      }
    }
    .container {
      position: relative;
      background: $darkColor;
      color: #fff;
      display: block;
      text-align: center;
      border-radius: 0 0 0 10px;
      border: 3px solid $darkColor;
      max-height: 110px;
      height: 110px;
      font-size: inherit;
      line-height: 1em;
      // position: relative;
      div {
        line-height: 1.5em;
      }

      .action {
        position: absolute;
        bottom: 0px;
        width: 100%;
      }
    }

    .container:before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      border-bottom: 20px solid #fff;
      border-left: 20px solid $darkColor;
      width: 0;
    }

    .title {
      color: white;
      background: 0;
      margin-top: 10px;
      text-transform: uppercase;
      display: block;
      font-size: 1.1em;
      background: $darkColor;
      padding: 5px;
      border-radius: 10px 10px 0 0;
    }
  }
}

.editTripForm .btn-edit-add {
  margin: 12px;
  width: 22%;

  @media screen and (max-width: 450px) {
    width: 90%;
  }
  @media screen and (min-width: 450px) and (max-width: 935px) {
    width: 44%;
  }
  @media screen and (min-width: 935.1px) and (max-width: 880px) {
    width: 21%;
  }

  a {
    width: 75%;
    display: block;
    text-align: center;
    height: auto;
    background: none;
    margin: auto;

    &:hover {
      text-decoration: none;
      .plus-icon {
        opacity: 0.7;
      }
    }

    .plus-icon {
      background: rgba(255, 255, 255, 1);
      color: $darkColor;
      font-size: 55px;
      line-height: 2em;
      max-height: 2em;
      text-align: center;
      border-radius: 0 0 10px 10px;
      border: 3px solid $darkColor;
    }

    .editBottom {
      margin: auto;
    }

    .text {
      color: white;
      background: 0;
      margin-top: 10px;
      text-transform: uppercase;
      font-size: 1.1em;
      background: $darkColor;
      padding: 5px;
      border-radius: 10px 10px 0 0;
    }

    .bold {
      font-weight: bold;
    }

    &.btn-tow {
      .text {
        background: $lightColor;
      }
      .plus-icon {
        color: $lightColor;
        border-color: $lightColor;
      }
    }
    &.btn-error {
      .text {
        background: $rouge-hover;
      }
      .plus-icon {
        color: $rouge-hover;
        border-color: $rouge-hover;
      }
    }
    &.btn-gearloss,
    &.btn-sar {
      .text {
        background: $bleu-trespale;
      }
      .plus-icon {
        color: $bleu-trespale;
        border-color: $bleu-trespale;
      }
    }
  }
}
</style>
