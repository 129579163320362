<template>
  <div id="" class="geoposition-widget">
    <div v-show="geopositionCurrentlyProcessing" class="text-center">
      <b-spinner label="Loading..." />
    </div>
    <b-row v-if="!disablePos">
      <b-col cols="auto">
        <button
          :disabled="disableButton"
          :id="'geoposition-widget-read-gps-button-' + id"
          class="auto-position-button"
          name="geoposition-auto-btn"
          type="button"
          @click="findLocation"
        >
          {{ $t("gps.read") }}
        </button>
      </b-col>
      <b-col v-if="geolocErrorCode" cols="auto" class="alert alert-warning">
        <span v-if="geolocErrorCode === 1">
          {{ $t("gps.unauthorized") }}
        </span>
        <span v-else-if="geolocErrorCode === 2">
          {{ $t("gps.disfonctional") }}
        </span>
        <span v-else-if="geolocErrorCode === 3">
          {{ $t("gps.unresponsive") }}
        </span>
        <span v-else>
          {{ $t("gps.error") }}
        </span>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="!disablePos" cols="12" md="6" lg="4">
        <p class="field-wrapper">
          <label>{{ $t("latitude") }}</label>
          <b-input
            v-model="lat1"
            v-mask="'##°'"
            :disabled="disableInput"
            :name="'gps_lat_' + id"
            :class="latErrorClass"
            placeholder="___°"
            class="activeInput1"
            @blur="focusChangedLat1"
          />
          <b-input
            v-model="lat2"
            v-mask="'##\''"
            :disabled="disableInput"
            :name="'gps_lat_2' + id"
            :class="latErrorClass"
            placeholder="___'"
            class="activeInput2"
            @blur="focusChangedLat2"
          />
          <b-input
            v-model="lat3"
            v-mask="'##\'\''"
            :disabled="disableInput"
            :name="'gps_lat_3' + id"
            :class="latErrorClass"
            placeholder="___''"
            class="activeInput2"
            @blur="focusChangedLat3"
          />
          <b-select
            v-model="lat4"
            :options="latOption"
            :disabled="disableInput"
            :name="'gps_lat_4' + id"
            :class="latErrorClass"
            class="activeInput3"
          />
          <b-form-input
            v-model="latDD"
            class="inactiveInput"
            disabled
          ></b-form-input>
          <label v-if="isLatError" class="error">
            {{ latErrorMsg }}
          </label>
        </p>
      </b-col>
      <b-col v-if="!disablePos" cols="12" md="6" lg="4">
        <p class="field-wrapper">
          <label>{{ $t("longitude") }}</label>
          <b-input
            class="activeInput1"
            v-model="long1"
            :disabled="disableInput"
            :name="'gps_long_' + id"
            :class="longErrorClass"
            :formatter="formatValue"
            placeholder="___°"
            @blur="focusChangedLong1"
          />
          <b-input
            v-model="long2"
            v-mask="'##\''"
            :disabled="disableInput"
            :name="'gps_long_2' + id"
            :class="longErrorClass"
            placeholder="___'"
            class="activeInput2"
            @blur="focusChangedLong2"
          />
          <b-input
            v-model="long3"
            v-mask="'##\'\''"
            :disabled="disableInput"
            :name="'gps_long_3' + id"
            :class="longErrorClass"
            placeholder="___''"
            class="activeInput2"
            @blur="focusChangedLong3"
          />
          <b-select
            v-model="long4"
            :options="longOption"
            :disabled="disableInput"
            :name="'gps_long_4' + id"
            :class="longErrorClass"
            class="activeInput3"
          />
          <b-form-input
            v-model="longDD"
            class="inactiveInput"
            disabled
          ></b-form-input>
          <label v-if="isLongError" class="error">
            {{ longErrorMsg }}
          </label>
        </p>
      </b-col>
      <b-col v-if="hasNafo" cols="6" lg="2">
        <p class="field-wrapper">
          <label>
            {{ $t("nafo") }}
          </label>
          <b-form-input
            v-model="nafoArea"
            :name="'gps_nafo_' + id"
            :class="nafoErrorClass"
            :required="hasNafo === $const.MANDATORY"
            disabled
          ></b-form-input>
          <label v-if="isNafoError" class="error">
            {{ nafoErrorMsg }}
          </label>
        </p>
      </b-col>
      <b-col v-if="hasGrid" cols="6" lg="2">
        <p class="field-wrapper">
          <label>
            {{ $t("grid") }}
          </label>
          <b-form-input
            v-model="gridPosition"
            :name="'gps_grid_' + id"
            :class="gridErrorClass"
            :required="hasGrid === $const.MANDATORY"
            disabled
          />
          <label v-if="isGridError" class="error">
            {{ gridErrorMsg }}
          </label>
        </p>
      </b-col>
      <b-col v-if="hasLobsterGrid" cols="6" lg="2">
        <LobsterGrid
          v-model="lgridPosition"
          :area="area"
          :refresh="refeshError"
          :required="hasLobsterGrid === $const.MANDATORY"
          @input="selectLGrid"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LobsterGrid from "@/components/LobsterGrid.vue";
import Geolocation from "@/utils/geolocation.js";
import Validator from "@/utils/validator.js";
import { mapping } from "@/utils/FormStateMapping";
import { mapState } from "vuex";

export default {
  name: "GeopositionWidget",
  components: {
    LobsterGrid
  },
  props: {
    hasGrid: {
      type: Number,
      default: 0
    },
    hasLobsterGrid: {
      type: Number,
      default: 0
    },
    hasNafo: {
      type: Number,
      default: 0
    },
    disablePos: {
      type: Boolean,
      default: false
    },
    position: Object,
    maxLat: Array,
    minLat: Array,
    maxLong: Array,
    minLong: Array,
    gridValidationArray: Array,
    nafoGridValidationArray: Array,
    required: Boolean,
    refeshError: Number,
    area: Number
  },
  data: () => ({
    id: (Math.random() * 100000).toFixed(0),
    autolat: null,
    autolon: null,
    // TODO: Réviser latOption et longOption pour avoir français et anglais
    latOption: [
      {
        text: "N",
        value: "N"
      },
      {
        text: "S",
        value: "S"
      }
    ],
    longOption: [
      {
        text: "E",
        value: "E"
      },
      {
        text: "W",
        value: "W"
      }
    ],
    // Note:
    //   DMS = Degree, minute, second
    //   DD  = Decimal Degree
    latDMS: "__°__'__\"N",
    lat1: null,
    lat2: null,
    lat3: null,
    lat4: null,
    latDD: null,
    longDMS: "__°__'__\"W",
    long1: null,
    long2: null,
    long3: null,
    long4: null,
    longDD: null,
    gridPosition: null,
    lgridPosition: null,
    gridOption: [""], // TODO: Not used !?!
    nafoArea: null,
    maxWait: 10000,
    geopositionCurrentlyProcessing: false,
    disableInput: false,
    disableButton: false,
    geolocErrorCode: 0,
    isLatError: false,
    isLongError: false,
    isNafoError: false,
    isGridError: false,
    latErrorClass: { error: false },
    longErrorClass: { error: false },
    nafoErrorClass: { error: false },
    gridErrorClass: { error: false },
    latErrorMsg: "",
    longErrorMsg: "",
    nafoErrorMsg: "",
    gridErrorMsg: "",
    lat4first: true,
    long4first: true
  }),
  computed: {
    ...mapState({
      gridMapMagdalenIslands: state =>
        state.editTripSubform[mapping.GridMapMagdalenIslands.stateName]
    })
  },
  watch: {
    gridValidationArray() {
      if (this.gridPosition) {
        this.checkError();
      }
    },
    nafoGridValidationArray() {
      if (this.nafoArea) {
        this.checkError();
      }
    },
    latDD(newValue, oldValue) {
      this.getGridAndNafo();
    },
    longDD() {
      this.getGridAndNafo();
    },
    lat4() {
      if (!this.lat4first) {
        this.focusChangedLat(this.lat4, 4);
      } else {
        this.lat4first = false;
      }
    },
    long4() {
      if (!this.long4first) {
        this.focusChangedLong(this.long4, 4);
      } else {
        this.long4first = false;
      }
    },
    isLatError() {
      this.latErrorClass.error = this.isLatError;
    },
    isLongError() {
      this.longErrorClass.error = this.isLongError;
    },
    isNafoError() {
      this.nafoErrorClass.error = this.isNafoError;
    },
    isGridError() {
      this.gridErrorClass.error = this.isGridError;
    },
    refeshError() {
      this.checkError();
    }
  },
  methods: {
    emitError() {
      let object = {};
      object["gps_lat_" + this.id] = this.isLatError;
      object["gps_long_" + this.id] = this.isLongError;
      object["gps_nafo_" + this.id] = this.isNafoError;
      object["gps_grid_" + this.id] = this.isGridError;
      this.$emit("error", object);
    },
    checkError() {
      let rt = {};
      if (!this.disablePos) {
        rt = Validator.genericValidation(this.latDD, {
          type: "gps_lat",
          required: this.required,
          minLat: this.minLat,
          maxLat: this.maxLat
        });

        this.isLatError = rt.error;
        this.latErrorMsg = rt.msg;

        rt = Validator.genericValidation(this.longDD, {
          type: "gps_long",
          required: this.required,
          minLong: this.minLong,
          maxLong: this.maxLong
        });

        this.isLongError = rt.error;
        this.longErrorMsg = rt.msg;
      }

      if (this.hasNafo) {
        rt = Validator.genericValidation(this.nafoArea, {
          type: "nafoValidation",
          required: this.hasNafo === this.$const.MANDATORY,
          nafoGridValidationArray: this.nafoGridValidationArray
        });

        this.isNafoError = rt.error;
        this.nafoErrorMsg = rt.msg;
      }

      if (this.hasGrid) {
        if (this.hasNafo === this.$const.BLOCKED) {
          rt = Validator.genericValidation(this.gridPosition, {
            type: "gridValidation",
            gridValidationArray: this.gridValidationArray,
            required: this.required
          });
        } else {
          rt = Validator.genericValidation(this.gridPosition, {
            type: "nafoGridValidation",
            nafoPosition: this.nafoArea,
            nafoGridValidationArray: this.nafoGridValidationArray,
            required: this.required
          });
        }

        this.isGridError = rt.error;
        this.gridErrorMsg = rt.msg;
      }

      this.emitError();
    },
    getGridMap() {
      let map = 1;
      // @TODO: evaluate if there is a better way than hardcode limits in client, they fluctuate between forms
      if (this.gridMapMagdalenIslands == 3) {
        // form 235 rule 740, 46.9000 47.9667 -62.2333 -61.1667
        if (
          this.latDD >= 46.9 &&
          this.latDD <= 47.9667 &&
          this.longDD >= -62.2333 &&
          this.longDD <= -61.1667
        ) {
          map = 3;
        }
      } else if (this.gridMapMagdalenIslands == 4) {
        // form 234 rule 613, area 22 is 47.0383 48.7167 -64.0361 -60.0000
        if (
          this.latDD >= 47.0383 &&
          this.latDD <= 48.7167 &&
          this.longDD >= -64.0361 &&
          this.longDD <= -60.0
        ) {
          map = 4;
        }
      }
      return map;
    },
    getGridAndNafo() {
      if (this.hasGrid) {
        let value = Geolocation.getGridFromPositioWithMap(
          this.latDD,
          this.longDD,
          this.getGridMap()
        );
        this.gridPosition = value ? value : null;
        this.gridOption[0] = this.gridPosition;
      }

      if (this.hasNafo) {
        let value = Geolocation.getNafoFromPosition(this.latDD, this.longDD);
        if (value) {
          this.nafoArea = value[0];
        } else {
          this.nafoArea = null;
        }
      }

      this.checkError();
      this.$emit("binding", {
        latitude: this.latDD,
        longitude: this.longDD,
        nafo: this.nafoArea,
        grid: this.gridPosition,
        lgrid: this.lgridPosition,
        autolat: this.autolat,
        autolon: this.autolon
      });
    },
    selectLGrid() {
      this.$emit("binding", {
        latitude: this.latDD,
        longitude: this.longDD,
        nafo: this.nafoArea,
        grid: this.gridPosition,
        lgrid: this.lgridPosition,
        autolat: this.autolat,
        autolon: this.autolon
      });
    },
    findLocation() {
      if (!this.geopositionCurrentlyProcessing) {
        this.disableInput = true;
        this.disableButton = true;
        this.geopositionCurrentlyProcessing = true;
        this.geolocErrorCode = 0;

        navigator.geolocation.getCurrentPosition(
          this.foundLocation,
          this.findLocationFail,
          {
            timeout: this.maxWait,
            enableHighAccuracy: true
          }
        );
      }
    },
    foundLocation(position) {
      this.autolat = this.$const.YES;
      this.autolon = this.$const.YES;
      this.latDD = Math.round(position.coords.latitude * 10000) / 10000;
      this.longDD = Math.round(position.coords.longitude * 10000) / 10000;

      this.latDMS = Geolocation.convertDD2toDMS(this.latDD, "lat").toString;
      this.formatLat(this.latDMS);

      this.longDMS = Geolocation.convertDD2toDMS(this.longDD, "lon").toString;
      this.formatLong(this.longDMS);

      this.doneGeolocalising();
    },
    findLocationFail(err) {
      console.error("findLocationFail", err.code, err); // eslint-disable-line no-console
      this.geolocErrorCode = err.code;
      this.doneGeolocalising();
    },
    doneGeolocalising() {
      this.geopositionCurrentlyProcessing = false;
      this.disableInput = false;
      this.disableButton = false;
    },
    formatPosNew(value, template, index) {
      if (value !== null) {
        switch (index) {
          case 1:
            template = template.split("°");
            template[0] = value + "°";
            break;
          case 2:
            template = template.split("'");
            template[0] = template[0].split("°");
            template[0][0] += "°";
            template[0][1] = value + "'";
            template[0] = template[0].join("");
            break;
          case 3:
            template = template.split('"');
            template[0] = template[0].split("'");
            template[0][0] += "'";
            template[0][1] = value + '"';
            template[0] = template[0].join("");
            break;
          case 4:
            template = template.split('"');
            template[1] = '"' + value;
            break;
          default:
        }
      }

      return template.join("");
    },
    reverseValue(str) {
      return str.replace(/'|°/g, "");
    },
    formatValue(value) {
      let x = value;
      x = x.trim();
      x = x.replace(/[^0-9\.\,°]/g, "");
      return x;
    },
    focusChangedLat1(e) {
      this.focusChangedLat(this.lat1, 1);
    },
    focusChangedLat2(e) {
      this.focusChangedLat(this.lat2, 2);
    },
    focusChangedLat3(e) {
      this.focusChangedLat(this.lat3, 3);
    },
    focusChangedLat(value, index) {
      if (value !== null) {
        value = this.reverseValue(value);
        this.latDMS = this.formatPosNew(value, this.latDMS, index);
        const latValue =
          Math.round(Geolocation.convertDMStoDD2(this.latDMS) * 10000) / 10000;
        if (latValue !== this.latDD) {
          this.latDD = latValue;
          this.autolat = this.$const.NO;
        }
        const convert = Geolocation.convertDD2toDMS(this.latDD, "lat");
        if (convert !== false) {
          this.latDMS = convert.toString;
          this.formatLat(this.latDMS);
        }
      }
    },
    formatLat(lat) {
      this.lat1 = lat.split("°")[0] + "°";
      this.lat2 = lat.split("'")[0].split("°")[1] + "'";
      this.lat3 = lat.split('"')[0].split("'")[1] + "''";
      this.lat4 = lat.split('"')[1].trim();
    },
    focusChangedLong1(e) {
      this.focusChangedLong(this.long1, 1);
    },
    focusChangedLong2(e) {
      this.focusChangedLong(this.long2, 2);
    },
    focusChangedLong3(e) {
      this.focusChangedLong(this.long3, 3);
    },
    focusChangedLong(value, index) {
      if (value !== null) {
        value = this.reverseValue(value);
        this.longDMS = this.formatPosNew(value, this.longDMS, index);
        const longValue =
          Math.round(Geolocation.convertDMStoDD2(this.longDMS) * 10000) / 10000;
        if (longValue !== this.longDD) {
          this.longDD = longValue;
          this.autolon = this.$const.NO;
        }
        const convert = Geolocation.convertDD2toDMS(this.longDD, "lon");
        if (convert !== false) {
          this.longDMS = convert.toString;
          this.formatLong(this.longDMS);
        }
      }
    },
    formatLong(long) {
      this.long1 = long.split("°")[0] + "°";
      this.long2 = long.split("'")[0].split("°")[1] + "'";
      this.long3 = long.split('"')[0].split("'")[1] + "''";
      this.long4 = long.split('"')[1].trim();
    }
  },
  mounted() {
    if (this.position) {
      if (this.position.latitude) {
        this.latDD =
          Math.round(parseFloat(this.position.latitude) * 10000) / 10000;
      }

      if (this.position.autolat && this.position.autolat !== null) {
        this.autolat = this.position.autolat;
      }

      if (this.position.autolon && this.position.autolon !== null) {
        this.autolon = this.position.autolon;
      }

      if (this.position.longitude) {
        this.longDD =
          Math.round(parseFloat(this.position.longitude) * 10000) / 10000;
      }

      if (this.position.lgrid) {
        this.lgridPosition = this.position.lgrid;
      }

      if (this.latDD !== null) {
        this.latDMS = Geolocation.convertDD2toDMS(this.latDD, "lat").toString;
        this.formatLat(this.latDMS);
      }

      if (this.longDD !== null) {
        this.longDMS = Geolocation.convertDD2toDMS(this.longDD, "lon").toString;
        this.formatLong(this.longDMS);
      }
    }
  }
};
</script>
